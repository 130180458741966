/*
 * SupportFaq Messages
 *
 * This contains all the text for the SupportFaq container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SupportFaq';

export default defineMessages({
  supportAndFaq: {
    id: `${scope}.supportAndFaq`,
    defaultMessage: 'Support & Faq',
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: 'Support',
  },
  faq: {
    id: `${scope}.faq`,
    defaultMessage: 'FAQ',
  },
  whatPeopleCare: {
    id: `${scope}.whatPeopleCare`,
    defaultMessage: 'What is People Connect?',
  },
  whatPeopleCareAnswer: {
    id: `${scope}.whatPeopleCareAnswer`,
    defaultMessage:
      'People Connect is our mental health resource centre that was designed to support you and your families through mental health resources, an online mental health self-assessment, and affordable and accessible virtual counselling that works with your schedule.',
  },
  whatPeopleCareAnswerForLeader: {
    id: `${scope}.whatPeopleCareAnswerForLeader`,
    defaultMessage:
      'People Connect is our mental health resource centre that was designed to support plan sponsors, people leaders, and plan members and their families.{lineBreak}{lineBreak}Plan members and their eligible dependants are supported through access to mental health resources, an online mental health self-assessment, and affordable and accessible virtual counselling that works with their schedule.',
  },
  confidentiality: {
    id: `${scope}.confidentiality`,
    defaultMessage: 'What about confidentiality?',
  },
  confidentialityAnswer: {
    id: `${scope}.confidentialityAnswer`,
    defaultMessage:
      'Only you and your therapist have access to your mental health information. Therapists reside in Canada, are highly experienced and are regulated professionals. Strict Canadian regulations for online health services are followed and all information is fully encrypted on a regularly audited server.',
  },
  familyMembers: {
    id: `${scope}.familyMembers`,
    defaultMessage:
      'Can my eligible family members (including dependents) use this site?',
  },
  familyMembersAnswer: {
    id: `${scope}.familyMembersAnswer`,
    defaultMessage:
      'People Connect is available to you and your eligible family members (spouse/partner/dependent). However, the People Connect site is intended for adult use, older adolescents and young adults and is not meant to be a resource for young children. If you have concerns about a dependent using this site, please do not share the url to access with them.',
  },
  legislativeImplication: {
    id: `${scope}.legislativeImplication`,
    defaultMessage: 'What are the legislative implications?',
  },
  humanRights: {
    id: `${scope}.humanRights`,
    defaultMessage:
      '{bold} – Courts and tribunals across the country are increasingly adding scope and definition to an employer’s obligation to reasonably accommodate mental illness in the workplace.',
  },
  humanRightsTitle: {
    id: `${scope}.humanRightsTitle`,
    defaultMessage: 'Human Rights',
  },
  workersComp: {
    id: `${scope}.workersComp`,
    defaultMessage:
      '{bold} – Workers’ compensation boards across Canada accept claims for chronic mental stress and bullying.',
  },
  workersCompTitle: {
    id: `${scope}.workersCompTitle`,
    defaultMessage: 'Workers’ Compensation',
  },
  ohs: {
    id: `${scope}.ohs`,
    defaultMessage:
      '{bold} – Recognition that mental health and psychological safety fall within the scope of responsibility to provide a safe system of work under OHS legislation is increasing.',
  },
  ohsTitle: {
    id: `${scope}.ohsTitle`,
    defaultMessage: 'Occupational health and safety (OHS)',
  },
  compareEAP: {
    id: `${scope}.compareEAP`,
    defaultMessage: 'How does this compare to an Employee Assistance Program?',
  },
  compareEAPForLeader: {
    id: `${scope}.compareEAPForLeader`,
    defaultMessage: 'How does this compare to an Employee Assistance Program?',
  },
  compareEAPAnswer: {
    id: `${scope}.compareEAPAnswer`,
    defualtMessage:
      'An employee assistance program (EAP) is a brief and solution focused therapy for everyday concerns, work conflict, bereavement, stress, and life balance. It’s often used to help find access to childcare, eldercare, legal information, nutrition tips, and financial self-help options.{lineBreak}{lineBreak}People Connect provides access to affordable one on one professional virtual counselling sessions for ongoing mental health challenges and concerns. Plan members can claim these services through their paramedical coverage under their group benefits plan*.',
  },
  planDesign: {
    id: `${scope}.planDesign`,
    defaultMessage: '*Dependent on plan design',
  },
  compareEAPAnswerForLeader: {
    id: `${scope}.compareEAPAnswerForLeader`,
    defaultMessage:
      'EAP is utilized for everyday concerns, work conflict, bereavement, stress and life balance. EAP is brief and solution focused therapy, on average 2 to 4 sessions. EAP is for access to childcare, eldercare, legal, nutrition and financial self help options and workbooks. People Corporation’s People Connect offers mid-term therapies, support for workplace programs and tools for managers. People Connect provides access to affordable virtual therapy, in one on one professional sessions, that can be invoiced through your mental health, paramedical benefits. People Connect offers a digital assessment and the virtual video therapy includes psychiatric collaboration. You can use the EAP and then use this program if you need longer term therapy. You can use People Connect to help find good and affordable therapists.',
  },
  meetCounsellor: {
    id: `${scope}.meetCounsellor`,
    defualtMessage: 'Do I get to meet the counsellor to ensure a right fit?',
  },
  meetCounsellorAnswer: {
    id: `${scope}.meetCounsellorAnswer`,
    defualtMessage:
      'You’ll have access to a complimentary initial consultation with a counsellor of your choosing to determine whether they’re an appropriate fit for your needs. If you decide to work with that counsellor following the meet and greet, you can then schedule your first counselling session.',
  },
  whoUses: {
    id: `${scope}.whoUses`,
    defaultMessage: 'Will I know who uses the service?',
  },
  whoUsesAnswer: {
    id: `${scope}.whoUsesAnswer`,
    defaultMessage:
      'People Connect is a confidential service. Plan sponsors won’t have access to plan member information,but we do provide annual utilization reports to groups of 26+ plan members. Quarterly reports are available upon request. Groups with 25 plan members or less will receive confirmation of usage.',
  },
  accessTraining: {
    id: `${scope}.accessTraining`,
    defaultMessage:
      'How do people leaders and employers access consultation and training?',
  },
  accessTrainingAnswer: {
    id: `${scope}.accessTrainingAnswer`,
    defaultMessage:
      'The People Connect site provides access to a free resource library, as well as <b>fee for service</b> training programs and consulting services to help create a psychologically safe workplace. Custom programs like building a peer program and de-stigmatizing mental health with executive champions can be delivered onsite or virtually. Please contact your account manager for more information.',
    tagName: 'p',
  },
  accessPC: {
    id: `${scope}.accessPC`,
    defaultMessage: 'How do I access People Connect?',
  },
  accessPCAnswer: {
    id: `${scope}.accessPCAnswer`,
    defaultMessage:
      'Visit <a href="https://pcpeopleconnect.com/eap">pcpeopleconnect.com/eap</a> to create an account. You\'ll need to provide your name, email, date of birth, company name, indicate whether you’re a plan member or an eligible dependant, and create apassword. The main dashboard of the People Connect site features tabs that lead directly to the mental health self-assessment, virtual counselling, and the resource library. To learn more about getting started with People Connect, please watch this video tutorial.',
    tagName: 'p',
  },
  accessPCLeader: {
    id: `${scope}.accessPCLeader`,
    defaultMessage: 'How do plan members access People Connect?',
  },
  accessPCAnswerLeader: {
    id: `${scope}.accessPCAnswerLeader`,
    // defaultMessage: 'Plan members and their eligible dependants have access to People Connect through pcpeopleconnect.com. They must create an account and password, and will be required to provide their name, email, date of birth, company name, and indicate whether they are the plan member, an eligible dependant, or a people leader. The main dashboard of the People Connect site features tabs that lead directly to the mental health assessment, virtual counselling, and the resource library.',
    tagName: 'p',
  },
  accessPsychiatry: {
    id: `${scope}.accessPsychiatry`,
    defaultMessage: 'How do plan members access psychiatric services?',
  },
  accessPsychiatryAnswer: {
    id: `${scope}.accessPsychiatryAnswer`,
    defaultMessage:
      'Through ongoing work with their counsellor, together they’ll determine if further support is necessary. It’s up to the counsellor to request a consultation or collaborative session through the site. These services are in place for cases that require further support and come at no additional cost.',
  },
  assessmentClaim: {
    id: `${scope}.assessmentClaim`,
    defaultMessage:
      'Can the mental health assessment be used to adjudicate a plan member’s claim?',
  },
  assessmentClaimAnswer: {
    id: `${scope}.assessmentClaimAnswer`,
    defaultMessage:
      'No. An independent medical examination is used to adjudicate claims. The online mental health selfassessment is a tool that can be used to help identify and suggest a mental health diagnoses but doesn’t replace the opinion of a medical professional.',
  },
  whoPays: {
    id: `${scope}.whoPays`,
    defaultMessage: 'Who pays for the counselling service?',
  },
  whoPaysAnswer: {
    id: `${scope}.whoPaysAnswer`,
    defaultMessage:
      'Through your employer you will have a six-session allotment (number of hours per category is indicated in the both the mental health and work-life support dashboard). After using your allotted hours, you can easily continue mental health care with the same counsellor at the rate of $90/hour plus tax. This can be paid by a credit card through the site. Depending on your coverage, you may be able to submit a claim under your group benefits plan. Check your plan member booklet or contact your plan administrator for your coverage details. ',
  },
  whoPaysLeader: {
    id: `${scope}.whoPaysLeader`,
    defaultMessage: 'Who pays for subsequent therapy sessions?',
  },
  whoPaysAnswerLeader: {
    id: `${scope}.whoPaysAnswerLeader`,
    defaultMessage:
      'You pay for all additional sessions via credit card. Each 1 hour session is $75 and each 30 minute session is $37.50. You will be provided with a receipt. Depending on the coverage available through your employer’s group benefit program, some or all of the costs associated with this therapy could be an eligible expense. If you are planning on submitting these services for reimbursement, please ensure that you are covered PRIOR to starting any therapy treatment.',
  },
  howMany: {
    id: `${scope}.howMany`,
    defaultMessage:
      'How many languages, religions and conditions are offered for counselling?',
  },
  howManyAnswer: {
    id: `${scope}.howManyAnswer`,
    defaultMessage:
      'Counselling is offered in 20 languages, 5 religions and covers 20+ conditions.',
  },
  differentTherapists: {
    id: `${scope}.differentTherapists`,
    defaultMessage: 'What types of practitioners are available?',
  },
  differentTherapistsAnswer: {
    id: `${scope}.differentTherapistsAnswer`,
    defaultMessage:
      'Practitioners available include:<ul><li>CCC, Canadian Certified Counsellor</li><li>RCC, Registered Clinical Counsellor</li><li>RPC, Registered Professional Counsellor</li><li>RP, Registered Psychotherapist</li><li>RSW, Registered Social Worker</li><li>RCSW/LCSW, Registered/Licensed Clinical Social Worker</li></ul>You’ll be matched with a practitioner based on your needs, preferences, and coverage available through your group benefits plan. All practitioners are Canadian residents and are members of a college or association in good standing.',
    tagName: 'p',
  },
  differentTherapistsAnswerForLeader: {
    id: `${scope}.differentTherapistsAnswerForLeader`,
    defaultMessage:
      'Practitioners include psychotherapists, social workers, and registered counsellors. Plan members will be matched with a practitioner based on their needs, preferences, and coverage available through their group benefits plan. All practitioners are Canadian residents and are members of a College or Association in good standing.',
  },
  cancellationPolicy: {
    id: `${scope}.cancellationPolicy`,
    defaultMessage: 'What is the cancellation policy?',
  },
  cancellationPolicyAnswer: {
    id: `${scope}.cancellationPolicyAnswer`,
    defaultMessage:
      'Cancellations or reschedules must be made more than 24 hours before your appointment. Late cancellations or missed appointments will be charged for a 30-minute session regardless of the booked session length. If you cancel your free session less than 24 hours before or miss the appointment, your initial free session will be forfeited.',
  },
  accessPsychiatrist: {
    id: `${scope}.accessPsychiatrist`,
    defaultMessage: 'Do I also have access to a psychiatrist?',
  },
  accessPsychiatristAnswer: {
    id: `${scope}.accessPsychiatristAnswer`,
    defaultMessage:
      'Through ongoing work with your counsellor, together you will determine if further support is necessary. It’s up to your counsellor to request a consultation or collaborative session which comes at no additional cost.',
  },
  paramedical: {
    id: `${scope}.paramedical`,
    defaultMessage:
      'What if I don’t know my plan number or what coverage I have?',
  },
  paramedicalAnswer: {
    id: `${scope}.paramedicalAnswer`,
    defaultMessage:
      'Contact your plan administrator for any information regarding your group benefits plan. For coverage details, check your plan member booklet.',
  },
  haveAQuestion: {
    id: `${scope}.haveAQuestion`,
    defaultMessage:
      'Have a question/suggestion? Please feel free to contact our team.',
  },
  haveAQuestionEap: {
    id: `${scope}.haveAQuestionEap`,
    defaultMessage:
      'If you are in need of customer support, call toll-free: {phone}, and follow the prompts to reach a customer support team member or email our team at',
  },
  contactWith: {
    id: `${scope}.contactWith`,
    defaultMessage: 'Who do I contact with questions regarding People Connect?',
  },
  contactWithAnswer: {
    id: `${scope}.contactWithAnswer`,
    defaultMessage:
      "The People Connect website features a chat function available for support from 11 a.m. to 8 p.m. ET. Click on the green chat button on the bottom right side of the page, and you'll be connected to someone to answer your questions. For any additional Inquiries about People Connect, contact peopleconnect@peoplecorporation.com.",
    tagName: 'p',
  },
  contactWithAnswerLeader: {
    id: `${scope}.contactWithAnswerLeader`,
    defaultMessage:
      'Contact peopleconnect@peoplecorporation.com for any inquiries related to the People Connect mental health resource centre.',
    tagName: 'p',
  },
  eapLeaderFaqHeader: {
    id: `${scope}.eapLeaderFaqHeader`,
    defaultMessage: 'TO DO: HEADER',
  },
  eapLeaderFaqBody: {
    id: `${scope}.eapLeaderFaqBody`,
    defaultMessage: 'TO DO: Body',
  },
  eapFaqHeader: {
    id: `${scope}.eapLeaderFaqHeader`,
    defaultMessage: 'TO DO: HEADER',
  },
  eapFaqBody: {
    id: `${scope}.eapLeaderFaqBody`,
    defaultMessage: 'TO DO: Body',
  },
  eapWhatIsPeople: {
    id: `${scope}.eapWhatIsPeople`,
    defaultMessage: 'What is People Connect EAP?',
  },
  eapWhatIsPeopleBody: {
    id: `${scope}.eapWhatIsPeopleBody`,
    defaultMessage:
      'People Connect Employee Assistance Program offers secure and confidential video counselling anytime, anywhere, with a clinician matched to you based on your needs. You and your family have access to health resources, an online mental health self-assessment, a clinician matching process to make sure your preferences are prioritized.',
  },
  eapConfidentiality: {
    id: `${scope}.eapConfidentiality`,
    defaultMessage: 'What about confidentiality?',
  },
  eapConfidentialityBody: {
    id: `${scope}.eapConfidentialityBody`,
    defaultMessage:
      'Only you and your practitioner have access to your health information. There are strict Canadian regulations for online health services and all information is fully encrypted on a regularly audited server. None of your personal information is shared with your employer.',
  },
  eapCanFamily: {
    id: `${scope}.eapCanFamily`,
    defaultMessage: 'Can my family members use People Connect EAP?',
  },
  eapCanFamilyBody: {
    id: `${scope}.eapCanFamilyBody`,
    defaultMessage:
      'People Connect EAP is available to you and your eligible family members (spouse/partner/dependant) Persons under the age of 15 can use the service at the discretion of the clinician.',
  },
  eapHowDoIAccess: {
    id: `${scope}.eapHowDoIAccess`,
    defaultMessage: 'How do I access People Connect EAP?',
  },
  eapHowDoIAccessBody: {
    id: `${scope}.eapHowDoIAccessBody`,
    defaultMessage:
      "Visit <a href='https://pcpeopleconnect.com/eap'>pcpeopleconnect.com/eap</a> to create an account. You'll need to provide your name, email, date of birth, company name, indicate whether you’re a plan member or an eligible dependant, and create a password. The main dashboard of the People Connect EAP site features tabs that lead directly to the mental health self-assessment, mental health and Work, Health and Life Services, and the resource library. To learn more about getting started with People Connect EAP, please watch this video tutorial.",
  },
  eapHowDoesThisCompare: {
    id: `${scope}.eapHowDoesThisCompare`,
    defaultMessage:
      'How does this compare to other Employee Assistance Programs?',
  },
  eapHowDoesThisCompareBody: {
    id: `${scope}.eapHowDoesThisCompareBody`,
    defaultMessage:
      'People Connect EAP provides access to clinicians to meet all your work life needs, through a personalized matching system. This allows you to select the clinician you wish to work with. Other EAP’s typically provide short term support, but People Connect EAP gives you the ability to continue with the same clinicians beyond your initial EAP allotment when longer support is needed by using your extended health benefit plan* or out of pocket. *Dependent on plan design',
  },
  eapDoIGetToMeet: {
    id: `${scope}.eapDoIGetToMeet`,
    defaultMessage: 'Do I get to meet the counsellor to ensure a right fit?',
  },
  eapDoIGetToMeetBody: {
    id: `${scope}.eapDoIGetToMeetBody`,
    defaultMessage:
      'You’ll have access to a complimentary initial consultation with a clinician of your choosing to determine whether they’re an appropriate fit for your needs. If you decide to work with that clinician following the meet and greet, you can then schedule your first clinical session.',
  },
  eapWhoPays: {
    id: `${scope}.eapWhoPays`,
    defaultMessage: 'Who pays for the counselling service?',
  },
  eapWhoPaysBody: {
    id: `${scope}.eapWhoPaysBody`,
    defaultMessage:
      'Through your employer you will have a six-session allotment (number of hours per category is indicated in the both the mental health and work-life support dashboard). After using your allotted hours, you can easily continue mental health care with the same counsellor at the rate of $90/hour plus tax. This can be paid by a credit card through the site. Depending on your coverage, you may be able to submit a claim under your group benefits plan. Check your plan member booklet or contact your plan administrator for your coverage details.',
  },
  eapRunOut: {
    id: `${scope}.eapRunOut`,
    defaultMessage: 'What if I run out of sponsored hours?',
  },
  eapRunOutBody: {
    id: `${scope}.eapRunOutBody`,
    defaultMessage:
      'Following the allotment of credits, individuals are able to continue to see the same counsellor and apply the cost towards either their paramedical coverage, Health Spending Account (HSA) or pay out of pocket.',
  },
  eapReligion: {
    id: `${scope}.eapReligion`,
    defaultMessage:
      'How many languages and religions are offered for coaching and counselling support?',
  },
  eapReligionBody: {
    id: `${scope}.eapReligionBody`,
    defaultMessage:
      'You can choose from over 20 languages and over 10 religions.',
  },
  eapAdvisoryServices: {
    id: `${scope}.eapAdvisoryServices`,
    defaultMessage: 'How do advisory services work?',
  },
  eapAdvisoryServicesBody: {
    id: `${scope}.eapAdvisoryServicesBody`,
    defaultMessage:
      '<b>Financial services:</b> You will be able to enter two date and time options for a ﬁnancial advisor to contact them. <br/><br/><b>Legal consultations:</b> You will be provided with a toll-free number and a unique consultation number to site. On the platform, you will be able to add in more details regarding your request.<br/><br/> <b>Health coaching / life transitions:</b> When a request for either health coaching or life transitions is submitted, individual will be provided with available times for an assessment by a registered nurse on our clinical team. The nurse will perform a thorough assessment and determine the best resources, referral, or health teaching to help individuals work through a variety of different health related goals. Depending on the need, this may involve referral to a nutritionist, life coach, registered dietician, ergonomist, pediatric nurse, addiction specialist, sleep coach, naturopath etc.<br/><br/> <b>Career coaching:</b> When a request for career coaching is received, the request is reviewed and referred to an accredited career coach who can support career management, career transition and retirement transition.',
  },
  eapPractitioner: {
    id: `${scope}.eapPractitioner`,
    defaultMessage: 'What types of practitioners are available?',
  },
  eapPractitionerBody: {
    id: `${scope}.eapPractitionerBody`,
    defaultMessage:
      'Clinicians include social workers, psychotherapists and psychologists, with a minimum master’s level of education and are currently registered and in good standing with their discipline’s professional regulatory college. They are all Canadian residents and have a minimum of five years of clinical experience. The Worklife/Advisory Services team (life, career, etc) include a network of professional financial advisors, attorneys, career consultants, Registered Nurses, Registered Dietitians, Kinesiologists, certified health coaches and more. You’ll be matched with a practitioner based on your needs, preferences, and coverage available through your group benefits plan. All practitioners are Canadian residents and are members of a college or association in good standing.',
  },
  eapPsychiatrist: {
    id: `${scope}.eapPsychiatrist`,
    defaultMessage:
      'Do I also have access to a psychiatrist for mental health support?',
  },
  eapPsychiatristBody: {
    id: `${scope}.eapPsychiatristBody`,
    defaultMessage:
      'Through ongoing work with your clinician, together you will determine if further support is necessary. It’s up to your clinician to request a consultation or collaborative session which comes at no additional cost.',
  },
  eapWhoDoIContact: {
    id: `${scope}.eapWhoDoIContact`,
    defaultMessage:
      'Who do I contact with questions regarding People Connect Employee Assistance Program?',
  },
  eapWhoDoIContactBody: {
    id: `${scope}.eapWhoDoIContactBody`,
    defaultMessage:
      "The People Connect EAP website features a chat function available for support from 11 a.m. to 8 p.m. ET. Click on the green chat button on the bottom right side of the page, and you'll be connected to someone to answer your questions. For any additional Inquiries about People Connect EAP, contact <a href='mailto:healthsolutions@peoplecorporation.com'>healthsolutions@peoplecorporation.com</a>",
  },
  bapFaqHeader: {
    id: `${scope}.bapFaqHeader`,
    defaultMessage: 'Frequently asked questions for plan members',
  },
  bapTitle: {
    id: `${scope}.bapTitle`,
    defaultMessage: 'People Connect Carepath',
  },
  bapSubtitle: {
    id: `${scope}.bapSubtitle`,
    defaultMessage:
      'People Connect Carepath is a unique service designed to provide health navigation and support to you and your dependants. It contains the following core elements:<br/><br/><ul> <li>Health navigation – a suite of support services including treatment review, health coaching and education, health care navigation services, and more </li> <li>Second opinion services – an expert review by a specialist to ensure that your recommended treatment plan is consistent with best clinical practice guidelines</li> <li>Cancer assistance – specialized support provided by oncology nurses to help ease the physical and emotional impact of cancer</li> <li>Mental health support – access to an online mental health assessment, user-matched therapist options, and psychiatric collaboration</li> </ul>',
  },
  dependantUse: {
    id: `${scope}.dependantUse`,
    defaultMessage: 'Can my covered dependants use People Connect Carepath?',
  },
  dependantUseBody: {
    id: `${scope}.dependantUseBody`,
    defaultMessage:
      'Yes. Health navigation, medical second opinion services, and cancer assistance are available for all eligible dependants. However, People Connect mental health therapy and support is intended for adults and older adolescents and isn’t meant to be a resource for young children.',
  },
  secondOpinion: {
    id: `${scope}.secondOpinion`,
    defaultMessage: 'What is second opinion?',
  },
  secondOpinionBody: {
    id: `${scope}.secondOpinionBody`,
    defaultMessage:
      'A second opinion is an expert medical review from a physician who specializes in the diagnosis and treatment of a health condition to bring greater certainty around diagnosis and next steps to the patient. All medical second opinions are completed in partnership with the Cleveland Clinic and Cleveland Clinic Canada. The reviewing physician conducts a virtual consult with the patient and the treatment team to discuss the outcome.',
  },
  nurseNavigator: {
    id: `${scope}.nurseNavigator`,
    defaultMessage: 'What is a nurse navigator?',
  },
  nurseNavigatorBody: {
    id: `${scope}.nurseNavigatorBody`,
    defaultMessage:
      'A nurse navigator is a point of contact at Carepath. They’re there to assist through diagnosis, treatment, and plan of care.',
  },
  referrals: {
    id: `${scope}.referrals`,
    defaultMessage:
      'How do referrals work for additional tests and/or treatment, if required?',
  },
  referralsBody: {
    id: `${scope}.referralsBody`,
    defaultMessage:
      'Carepath can’t make referrals for diagnostic services or treatment. A nurse navigator will help the patient understand their options and how to access any tests, clinical trials, and local treatment providers (physiotherapy, occupational therapy, pain programs, etc.)',
  },
  assessment: {
    id: `${scope}.assessment`,
    defaultMessage: 'Do I undergo an assessment by a physician?',
  },
  assessmentBody: {
    id: `${scope}.assessmentBody`,
    defaultMessage:
      'No, there is no physical assessment that takes place. This service works in parallel to the public health care system and is not a replacement for a patient’s current health care team. Intake is done by phone with a member of the Carepath team who will provide the patient with the appropriate consent forms. Once completed, a nurse navigator will reach out within 1-2 business days where they will review the patient’s medical history and answer any questions about the diagnosis and health navigation services.',
  },
  cancerAssistance: {
    id: `${scope}.cancerAssistance`,
    defaultMessage: 'What is cancer assistance?',
  },
  cancerAssistanceBody: {
    id: `${scope}.cancerAssistanceBody`,
    defaultMessage:
      'Cancer assistance offers specialized care and support provided by oncology nurses with the goal of reducing the physical and emotional impact of cancer. The patient is assigned their own oncology nurse who specializes in their type of cancer and provides support throughout treatment.',
  },
  nurseNavigatorLimit: {
    id: `${scope}.nurseNavigatorLimit`,
    defaultMessage:
      'Is there a limit to the number of times I can speak to a nurse navigator at Carepath?',
  },
  nurseNavigatorLimitBody: {
    id: `${scope}.nurseNavigatorLimitBody`,
    defaultMessage:
      'There’s no limit to how many interactions the patient can have with a nurse navigator even if called for different or multiple medical conditions over time. The patient discharges the nurse navigator when ready - not the other way around.',
  },
  mentalHealthSupport: {
    id: `${scope}.mentalHealthSupport`,
    defaultMessage: 'What is the mental health support service?',
  },
  mentalHealthSupportBody: {
    id: `${scope}.mentalHealthSupportBody`,
    defaultMessage:
      'We recognize that going through health difficulties can be challenging. If an appointment with a therapist or reach out for mental health support is needed, we offer a virtual therapy tool for information, assessment, and appointments.',
  },
  employerKnowledge: {
    id: `${scope}.employerKnowledge`,
    defaultMessage: 'Will my employer know anything about my health condition?',
  },
  employerKnowledgeBody: {
    id: `${scope}.employerKnowledgeBody`,
    defaultMessage:
      'This service is part of your group benefits plan. All information provided is kept confidential.',
  },
  serviceUsage: {
    id: `${scope}.serviceUsage`,
    defaultMessage:
      'How do I use the service and where do I find information on my plan coverage?',
  },
  serviceUsageBody: {
    id: `${scope}.serviceUsageBody`,
    defaultMessage:
      'An intake team representative can be reached by phone at 1-866-883-5956 between 8 a.m. -8 p.m. ET. Alternatively, visit the website at peopleconnectcarepath.ca, enter the required contact information, and a representative will reach out to schedule an appointment. Information on your coverage can be found in your plan member booklet. If you don’t have a plan member booklet or don’t know where to find the information, contact your plan administrator. For more information Contact <a href="mailto:healthsolutions@peoplecorporation.com">healthsolutions@peoplecorporation.com</a> for any inquiries related to People Connect Carepath.',
  },
  dependantsUsage: {
    id: `${scope}.dependantsUsage`,
    defaultMessage:
      'Can a plan member’s dependants use People Connect Carepath?',
  },
  dependantsUsageBody: {
    id: `${scope}.dependantsUsageBody`,
    defaultMessage:
      'Yes. Health navigation, medical second opinion services, and cancer assistance are available for all eligible dependants. However, People Connect mental health therapy and support is intended for adults and older adolescents and isn’t meant to be a resource for young children.',
  },
  communicationProvided: {
    id: `${scope}.communicationProvided`,
    defaultMessage: 'What communication is provided to plan members?',
  },
  communicationProvidedBody: {
    id: `${scope}.communicationProvidedBody`,
    defaultMessage:
      'We’ll provide an initial plan member email, poster, and FAQ document that can be used as part of onboarding or posted internally (intranet, etc). Our dedicated site provides easy access to registration for therapy and support.',
  },
  usageReports: {
    id: `${scope}.usageReports`,
    defaultMessage: 'Will I know how many people have used the services?',
  },
  usageReportsBody: {
    id: `${scope}.usageReportsBody`,
    defaultMessage:
      'We can provide you with quarterly, aggregated usage reports as requested depending on your group’s size and your reporting requirements. Organizations of at least 26 plan members will receive full reports. Groups of 25 lives and under can receive basic reporting on the number of program users.',
  },
  cancerAssistance2: {
    id: `${scope}.cancerAssistance`,
    defaultMessage: 'What is cancer assistance?',
  },
  cancerAssistanceBody2: {
    id: `${scope}.cancerAssistanceBody`,
    defaultMessage:
      'Cancer assistance offers specialized care and support provided by oncology nurses with the goal of reducing the physical and emotional impact of cancer. The patient is assigned their own oncology nurse who specializes in their type of cancer and provides support throughout their treatment.',
  },
  annualLimit: {
    id: `${scope}.annualLimit`,
    defaultMessage:
      'Is there an annual limit on the number of times someone can reach out to People Connect Carepath?',
  },
  annualLimitBody: {
    id: `${scope}.annualLimitBody`,
    defaultMessage:
      'There’s no annual limit or cap. Patients are free to engage in the service as often as required.',
  },
  travelRequirement: {
    id: `${scope}.travelRequirement`,
    defaultMessage:
      'Do plan members need to travel or be seen in person to use this service?',
  },
  travelRequirementBody: {
    id: `${scope}.travelRequirementBody`,
    defaultMessage:
      'Not at all. All services provided through People Connect Carepath are provided telephonically and virtually ensuring timely access and support.',
  },
  secondOpinionSpeed: {
    id: `${scope}.secondOpinionSpeed`,
    defaultMessage: 'How quickly can a plan member receive a second opinion?',
  },
  secondOpinionSpeedBody: {
    id: `${scope}.secondOpinionSpeedBody`,
    defaultMessage:
      'Once Carepath receives all medical documentation and outreach is conducted, the report is generally available in two weeks with the practitioner follow up booked at that time.',
  },
  accessProcedure: {
    id: `${scope}.accessProcedure`,
    defaultMessage: 'How do plan members access the services?',
  },
  accessProcedureBody: {
    id: `${scope}.accessProcedureBody`,
    defaultMessage:
      'Plan members and their dependants can contact a representative of the Carepath intake team by phone at 1-866-883-5956 between 8am-8pm EST. Alternatively, by visiting the website at peopleconnectcarepath.ca, plan members can enter their contact information and the intake team will reach out to schedule an appointment. At the time of program launch, you’ll get the option to have People Connect send out a welcome email directly to plan members with a call to action to register for a confidential account.',
  },
  welcomeEmailOption: {
    id: `${scope}.welcomeEmailOption`,
    defaultMessage:
      'At the time of program launch, you’ll get the option to have People Connect send out a welcome email directly to plan members with a call to action to register for a confidential account.',
  },
  businessAssistanceAccess: {
    id: `${scope}.businessAssistanceAccess`,
    defaultMessage:
      'Can plan members access business assistance program services?',
  },
  businessAssistanceAccessBody: {
    id: `${scope}.businessAssistanceAccessBody`,
    defaultMessage:
      'No. Financial advisory, legal advisory, HR and management consultations and critical incident support services are only available to business owners. The services listed will only be visible when users register with a people leader account type.',
  },
  appointmentBooking: {
    id: `${scope}.appointmentBooking`,
    defaultMessage:
      'How do I book appointments with professionals through the business assistance program?',
  },
  appointmentBookingBody: {
    id: `${scope}.appointmentBookingBody`,
    defaultMessage:
      '<i><b>HR and management consultations</b></i><br/><br/> To arrange a virtual consultation, contact <a href="mailto:consultation@inkblottherapy.com">consultation@inkblottherapy.com</a>. For immediate support call: 1-855-933-0103.<br/><br/><i><b>Financial and legal advisory:</b></i><br/><br/>To arrange a virtual consultation, select additional services at the top of the dashboard and choose the service you would like to access, then click next. Select the category reason for your request and then click next. <ul><li>Financial: Enter your location and contact information and select two timeframes that you could be available for a phone call. Then select submit. A financial services representative will contact you directly to schedule your appointment.</li><li>Legal: Call the hotline number that appears and use the consultation number provided to initiate your request.</li></ul>Consultations can be arranged Monday – Friday during the following hours:<ul><li>Financial: 9am – 9pm EST</li><li>Legal: 9am – 8pm EST</li><li>HR and management consultations: 9am – 5pm EST</li></ul>To receive immediate support from R3 for critical incident support services (fee for service), please email <a href="mailto:info@R3c.com">info@R3c.com</a> or call 1-855-588-7590 (24/7 live call answer).',
  },
  supportAvailability: {
    id: `${scope}.supportAvailability`,
    defaultMessage:
      'How long after requesting an appointment will I receive support?',
  },
  supportAvailabilityBody: {
    id: `${scope}.supportAvailabilityBody`,
    defaultMessage:
      'After requesting an appointment for a HR and management consultation, financial and legal consultations, you can expect to receive an appointment within two days.',
  },
  consultationLimit: {
    id: `${scope}.consultationLimit`,
    defaultMessage:
      'Is there an annual limit on how many consultations I can receive through the business assistance program?',
  },
  consultationLimitBody: {
    id: `${scope}.consultationLimitBody`,
    defaultMessage:
      'No. HR and management consultations, financial advisory, and legal consultations are unlimited. Consultations are typically 30 minutes in length and provided virtually or by telephone.',
  },
  incidentSupportFees: {
    id: `${scope}.incidentSupportFees`,
    defaultMessage: 'What are the fees for critical incident support services?',
  },
  incidentSupportFeesBody: {
    id: `${scope}.incidentSupportFeesBody`,
    defaultMessage:
      'Acute disruptions, critical incidents, and systemic disruptions, including trauma-informed follow-up $295* per hour, per debriefer; 4-hour minimum including follow-up calls. Travel is charged at cost. Travel time is $100 per hour after the initial hour. After the first 60 km, $0.68/km is charged. Hotels, airfare, other expenses will be charged subject to approval.  ',
  },
  ongoingTrauma: {
    id: `${scope}.ongoingTrauma`,
    defaultMessage: 'Ongoing trauma-informed support',
  },
  ongoingTraumaBody: {
    id: `${scope}.ongoingTraumaBody`,
    defaultMessage:
      '$250* per hour for ongoing trauma support. Subject to pre-approval. If needed and identified through validated measures during follow-up we can offer ongoing trauma-informed specialized therapy. Services are provided by a unique and senior network of specialists who have completed trauma informed training and who understand the specific needs of those who have experienced a disruptive event and who may need immediate and specialized care.<br/><br/><i>*Remote locations subject to quote and may include premium fees for remote locations, costs and travel. <br/><br/>For more information<br/>For any additional information or support, please contact your BBD client specialist.</i>',
  },
  faqBapSubtitle: {
    id: `${scope}.faqBapSubtitle`,
    defaultMessage:
      'People Connect Carepath is a unique service designed to provide health navigation and support to your employees and their dependants. It contains the following core elements:<br/><br/><ul><li>Health navigation – a suite of support services including treatment review, health coaching and education, health care navigation services, and more</li><li>Second opinion services – an expert review by a specialized physician(s) to ensure the recommended treatment plan is consistent with best clinical practice guidelines</li><li>Cancer assistance – specialized support provided by oncology nurses to help ease the physical and emotional impact of cancer</li><li>Mental health support – access to an online mental health assessment, user-matched therapist options, and psychiatric collaboration</li></ul>',
  },
  faqBapTitle: {
    id: `${scope}.faqBapTitle`,
    defaultMessage: 'Support for employees',
  },
  bapFaqHeader2: {
    id: `${scope}.bapFaqHeader2`,
    defaultMessage: 'People Connect Carepath',
  },
  bapFaqHeader3: {
    id: `${scope}.bapFaqHeader3`,
    defaultMessage: 'Frequently asked questions for people leaders',
  },
  businessAssistanceProgram: {
    id: `${scope}.businessAssistanceProgram`,
    defaultMessage: 'Business assistance program',
  },
  businessAssistanceProgramSub: {
    id: `${scope}.businessAssistanceProgramSub`,
    defaultMessage: '<i>Support for business owners</i><br/><br/>',
  },
  businessAssistanceProgramBody: {
    id: `${scope}.businessAssistanceProgramBody`,
    defaultMessage:
      'The People Connect Business Assistance Program (BAP) is a unique feature available to business owners as part of the overall People Connect Carepath BAP program. In addition to access to the Carepath elements, the business assistance program provides business owners with access to unlimited legal advisory, financial advisory, HR and management consultations, plus critical incident support services (fee for service).',
  },
  titleThatOnlyExistsInFrench: {
    id: `${scope}.titleThatOnlyExistsInFrench`,
    defaultMessage: 'nothing should be here in english',
  },
});
