/*
 * VirtualCounselling Messages
 *
 * This contains all the text for the VirtualCounselling container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.VirtualCounselling';

export default defineMessages({
  virtualTherapy: {
    id: `${scope}.virtualTherapy`,
    defaultMessage: 'Virtual Therapy',
  },
  mentalHealthSupport: {
    id: `${scope}.mentalHealthSupport`,
    defaultMessage: 'Mental health support',
  },
  getSupport: {
    id: `${scope}.getSupport`,
    defaultMessage: 'Get Support',
  },
  peopleCareInkblot: {
    id: `${scope}.peopleCareInkblot`,
    defaultMessage:
      'People Connect and Inkblot have partnered to bring you comprehensive online counselling service.',
  },
  inkblotOffers: {
    id: `${scope}.inkblotOffers`,
    defaultMessage:
      'Inkblot offers secure and confidential video counselling anytime, anywhere, with a practitioner matched to your needs. You’ll be able to match based on preferences like language, religion, symptoms, and stressors.',
  },
  yourInitial: {
    id: `${scope}.yourInitial`,
    defaultMessage: 'Your initial 30 minute session is always free',
    tagName: 'p',
  },
  fromThere: {
    id: `${scope}.fromThere`,
    defaultMessage:
      'From there sessions are only $45.00 plus tax per 30 minutes ($90/hour plus tax)',
    tagName: 'p',
  },
  sessionsCanBe: {
    id: `${scope}.sessionsCanBe`,
    defaultMessage: 'Sessions can be booked in 30, 60, or 90 minute segments.',
  },
  noCreditCard: {
    id: `${scope}.noCreditCard`,
    defaultMessage:
      'No credit card required using optional automated reimbursement through your Benefits Administrator.',
  },
  youMayChoose: {
    id: `${scope}.youMayChoose`,
    defaultMessage:
      '<b>You may choose to submit the costs for this therapy through the employee benefits provided by your employer. Please ensure you check your coverage details by checking your benefits booklet or contacting your plan administrator.</b> Depending on the coverage available through your employer’s Group Benefit program, some or all of the costs associated with this counselling could be an eligible expense.  If you are planning on submitting these services for reimbursement, please ensure that you are covered PRIOR to starting any treatment.”',
  },
  startFeeling: {
    id: `${scope}.startFeeling`,
    defaultMessage: 'Start feeling better today.',
  },
  findMyCare: {
    id: `${scope}.findMyCare`,
    defaultMessage: 'GO TO INKBLOT',
  },
  workHealthAndLifeServices: {
    id: `${scope}.workHealthAndLifeServices`,
    defaultMessage: 'Work, Health and Life Services',
  },
  pleaseEnsure: {
    id: `${scope}.pleaseEnsure`,
    defaultMessage:
      'Please ensure you check your coverage details by referring to your group benefit plan booklet or contacting your plan administrator within your organization.',
  },
  eapBody: {
    id: `${scope}.eapBody`,
    defaultMessage:
      'People Connect and Inkblot have partnered to bring you a comprehensive, virtual employee assistance program and mental health support service.',
  },
  eapBody2: {
    id: `${scope}.eapBody2`,
    defaultMessage:
      'People Connect EAP offers secure and confidential video counselling anytime, anywhere, with a clinician matched to you based on your needs. You’ll be able to connect with support that reflects your preferences.',
  },
  eapBody3: {
    id: `${scope}.eapBody3`,
    defaultMessage:
      'Through the People Connect EAP, each employee and subsequent dependent and/or spouse will have access to: <ul><li>Individual counselling</li> <li>Couples counselling</li> <li>Health coaching</li> <li>Life coaching</li> <li>Career coaching</li> <li>Legal consultations, as needed</li> <li>24/7 crisis support </li></ul>',
  },
  eapBody4: {
    id: `${scope}.eapBody4`,
    defaultMessage:
      'After you have used your sponsored hour, you can continue working with your same counsellor or coach at a rate of $90 plus tax per hour. You may choose to submit the costs for this therapy through the employee benefits provided by your employer or pay privately out of pocket. Please ensure you check your coverage details by checking your benefits booklet or contacting your plan administrator.',
  },
  bapBody: {
    id: `${scope}.bapBody`,
    defaultMessage:
      'People Connect Carepath offers secure and confidential video counselling anytime, anywhere, with a clinician matched to you based on your needs and preferences. ',
  },
  bapBody2: {
    id: `${scope}.bapBody2`,
    defaultMessage:
      'Through People Connect Carepath, you and your eligible dependents will have access to:',
  },
  bapBody3: {
    id: `${scope}.bapBody3`,
    defaultMessage:
      '<ul><li>A comprehensive mental health resource library with access to articles, videos, and audio files</li><li>Virtual individual and couples counselling</li><li>Access to a proprietary Diagnostic and Statistical Manual (DSM) based mental health self-assessment tool</li><li>Virtual psychiatric collaboration (in cases requiring additional support)</li></ul>',
  },
  bapBody4: {
    id: `${scope}.bapBody4`,
    defaultMessage:
      'After the complimentary initial consultation with a counsellor of your choosing to determine whether there’s an appropriate fit, one sponsored hour is provided, meaning your first 60 minutes of counselling are free. After you have used your sponsored hour, you can continue working with your same counsellor or coach at a rate of $90/hour plus tax. You may choose to submit the costs for this therapy through the employee benefits provided by your employer or pay privately out of pocket. Please ensure you check your coverage details by checking your benefits booklet or contacting your plan administrator.',
  },
  bapBody5: {
    id: `${scope}.bapBody5`,
    defaultMessage:
      'People Connect and Inkblot have partnered to bring you a comprehensive, virtual mental health support service.',
  },
  bapPeopleLeaderBody: {
    id: `${scope}.bapPeopleLeaderBody`,
    defaultMessage:
      "People Connect Carepath offers secure and confidential video counselling with a clinician matched to you based on your needs and preferences.</p><p>Through People Connect Carepath, you have access to:</p><ul><li>Unlimited financial and legal advisory for business-related needs *<br/>*Booked in 30 minute sessions. Please visit the 'Work, Health and Life Services' tab at the top of the Inkblot dashboard (accessed by clicking on the pink button below)</li></ul><p>You and your eligible dependents will also have access to:</p><ul><li>Virtual individual and couples counselling</li><li>Virtual psychiatric collaboration (in cases requiring additional support)</li></ul><p>After the complimentary initial consultation with a counsellor of your choice to determine whether there’s an appropriate fit, one sponsored hour is provided, meaning your first 60 minutes of counselling are free. After you have used your sponsored hour, you can continue working with your same counsellor or coach at a rate of $90/hour plus tax. You may choose to submit the costs for this therapy through the employee benefits provided by your employer or pay privately out of pocket. Please ensure you check your coverage details by checking your benefits booklet or contacting your plan administrator.</p><p>People Connect and Inkblot have partnered to bring you a comprehensive, virtual mental health support service.",
  },
});
